$tablet-width: 768px;
$desktop-width: 1024px;
$large-mobile:420px;
$mobile-protiat:767px;

@mixin mobileL{
  @media (max-width:#{$large-mobile})  {
    @content;
  }
}

@mixin mobileProtriet{
  @media (min-width:#{$mobile-protiat}) and (max-width:#{$large-mobile})  {
    @content;
  }
}


@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}