.UnnlockChatBotTitle {
  background-color: white;
  border-radius: 16px;
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #4389fa;
  margin: 30px;
  margin-bottom: 0px;
}
.UnnlockChatBot {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  .UnnlockChatBotConatiner {
    background: #ffffff;
    border-radius: 16px;
    width: 70.83%;
    padding: 20px;
    .UnnlockChatBotQuestions {
      .ant-radio-wrapper {
        display: block;
      }
    }
    .or {
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
    }
    .questionInput {
      margin: 10px 0px;
      display: flex;
      justify-content: center;
      input {
        border: 1px solid #dee5ff;
        border-radius: 8px;
        padding: 10px;
        width: 50%;
        margin-right: 10px;
        outline: none;
      }
    }
    .answerSection {
      padding: 15px 0px;
      textarea {
        border: none;
        width: 100%;
        height: 45vh;
        outline: none;
      }
    }
  }
  .UnnlockChatBotTips {
    width: 25%;
  }
}
