@import "../../styles/variables.scss";

.ResumeOptimizer-container {
  width: 100%;
  height: 100%;
  color: $primaryFontColor;

  .resume_optimizer_header {
    background-color: white;
    border-radius: 16px;
    margin: 30px 30px 14px 30px;
    padding: 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 30px 15px;
    }

    .resume_optimizer__header__div {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      color: rgb(67, 137, 250);

      @media screen and (max-width: 768px) {
        padding-bottom: 1.5rem;
      }

      div {
        margin-right: 10px;
      }
    }

    .back_btn {
      width: 20px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    .resume_vault {
      font-size: 20px;
      font-weight: 600;
      text-decoration: underline;
      // color: $primaryFontColor;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .resume_name {
      border-left: 2px solid $primaryFontColor;
      // color: $primaryFontColor;
      font-size: 20px;
      padding-left: 15px;
      margin: 0;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
      }
    }

    .template_menue_selected_title {
      color: #4389fa;
      font-size: 16px;
      border: 0.5px solid #606060;
      border-radius: 10px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    .play_btn {
      border: 0.5px solid #606060;
      border-radius: 10px;
      height: 35px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }

  .resume_optimizer_body {
    padding: 0px 30px 30px;

    @media screen and (max-width: 768px) {
      padding: 0px 15px 15px;
    }

    .resume_optimizer_body_left {
      border-radius: 8px;
      overflow: hidden;

      .RO_body_section {
        background: white;
        padding: 20px 10px;
        border-right: 4px solid rgb(233, 241, 255);
        border-radius: 16px 0px 0px 16px;

        .title {
          color: $primaryFontColor;
          font-weight: 600;
          font-size: 16px;
          // text-align:;
          margin-bottom: 20px;
        }

        .item,
        .item_active {
          margin-bottom: 10px;
          color: #606060;
          font-size: 12px;
          text-align: center;
          cursor: pointer;
          padding: 3px 0px;

          img {
            width: 20px;
          }
        }

        .item_active {
          padding: 3px 0px;
          background: #ecf3ff;
          // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
        }
        @media screen and (max-width: 768px) {
          .item,
          .item_active {
            text-align: left;
            display: flex;
            align-items: center;
            padding: 15px;
          }
        }
      }

      .content {
        background: #ffffff;
        // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
        border-radius: 0px 16px 16px 0px;
        padding: 20px;

        .Ai_suggestion_header {
          display: flex;
          align-items: center;
          cursor: pointer;

          div {
            margin: 0px 15px;
            color: #606060;
            font-weight: 400;
            font-size: 14px;
          }
        }

        .Ai_suggestion_content {
          background: rgba(67, 137, 250, 0.1);
          border-radius: 16px;
          padding: 15px;
          margin-top: 15px;
          color: #606060;
          cursor: pointer;
        }
      }
    }

    .resume_optimizer_body_right {
      // background: #ffffff;
      // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
      // border-radius: 16px;
      // padding: 15px;

      @media screen and (max-width: 768px) {
        margin-top: 24px;
      }

      .title {
        padding: 15px 0px;

        img {
          margin-right: 20px;
        }

        span {
          font-weight: 500;
          font-size: 24px;
          color: $primaryFontColor;
          letter-spacing: 0.01em;
          font-feature-settings: "tnum" on, "lnum" on;
        }
      }

      .title-label {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 2rem;
        color: $primaryFontColor;
        letter-spacing: 0.011em;
      }

      .content {
        // background: rgba(67, 137, 250, 0.1);
        // border-radius: 24px;
        // padding: 15px;
        @extend .title-label;
        font-weight: normal;
        margin-bottom: 4rem;
      }

      .default_content {
        color: #606060;
        font-weight: 500;
        font-size: 20px;
        padding: 15px;
        text-align: center;
      }

      .employementSteps {
        background: #ffffff;
        // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        height: 100%;
        .title {
          border-bottom: 4px solid rgb(233, 241, 255);
          padding: 8px 16px;
          // display: flex;
          // align-items: center;
          .subtitle {
            margin-left: 19%;
          }
        }

        .selected_step {
          color: $primaryFontColor;
          padding: 8px 16px;
          position: relative;
          .step,
          .label {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          }

          .desc {
            color: rgba(38, 28, 75, 0.84);
            font-size: 14px;
          }

          .nextStepButton {
            background: #4389fa;
            border-radius: 8px;
            padding: 10px;
            width: 100%;
            border: none;
            color: #fefefe;
            margin-top: 15px;
            cursor: pointer;
          }
        }

        .unselected_step {
          color: rgba(38, 28, 75, 0.52);
          font-size: 16px;
          padding: 8px 16px;
        }

        .borderBottom {
          border: 0.5px solid rgba(38, 28, 75, 0.1);
          margin: 20px 0px;
        }
      }
    }

    .skills_suggestions_conatiner {
      background: #ffffff;
      // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      padding: 15px;
      margin-top: 25px;

      .skills_suggestions {
        .title {
          font-weight: 700;
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 5px;
        }
      }
    }

    .save-button {
      display: flex;
      justify-content: end;
      align-items: center;
      padding-bottom: 15px;

      button {
        font-size: 16px;
        color: #4389fa;
        background: rgba(67, 137, 250, 0.1);
        border-radius: 10px;
        border: none;
        padding: 7px 12px;
        cursor: pointer;
      }
    }
  }

  .otheres-save-button {
    font-size: 16px;
    color: #4389fa;
    background: rgba(67, 137, 250, 0.1);
    border-radius: 10px;
    border: none;
    padding: 7px 12px;
    cursor: pointer;
    margin: 0px 15px;
  }

  .ROSelectionInput.ant-select {
    width: 100%;
    border: none;

    .ant-select-selector {
      border: 1px solid #dee5ff;
      padding: 10px;
      border-radius: 8px;
      height: 50px;
      color: $primaryFontColor;
    }
  }

  .dateSelectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      font-size: 14px;
      color: "#606060";
    }

    .monthSelection.ant-select {
      width: 40%;
      border: none;

      .ant-select-selector {
        border: 1px solid #dee5ff;
        padding: 10px;
        border-radius: 8px;
        height: 50px;
        color: $primaryFontColor;
      }
    }
  }
}

.template_menue.ant-dropdown-menu {
  border-radius: 10px;

  .ant-dropdown-menu-item-group-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.more_menue.ant-dropdown-menu {
  background: #1f1d25;
  border-radius: 10px;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: white;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
}

.add_otheres_modal {
  .content {
    height: 250px;
    overflow: auto;

    .title {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      margin-bottom: 20px;
    }

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 10px 0px;

      .folderIcon {
        margin: 0px 15px;
      }
    }
  }

  .submit_btnContainer {
    display: flex;
    justify-content: flex-end;

    .done_btn {
      font-weight: 600;
      font-size: 16px;
      color: #4389fa;
      background: rgba(67, 137, 250, 0.1);
      border-radius: 10px;
      border: none;
      padding: 7px 12px;
      cursor: pointer;
    }
  }
}

.addOtheres_model.ant-modal {
  .ant-modal-content {
    border-radius: 20px;
  }
}

.resumeOptimizerForm_input_container {
  width: 100%;
  input {
    width: 100%;
    border: none;
    font-weight: 400;
    font-size: 16px;
    background: white !important;
    color: $primaryFontColor;
    letter-spacing: 0.01em;
    &:focus {
      outline: none;
    }
  }

  .resumeOptimizerForm_input,
  .input_withError {
    background: white !important;
    border: 1px solid #dee5ff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    color: $primaryFontColor;
    height: 50px;

    .RO_label {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }

  .input_withError {
    border: 1px solid #e25151;
  }

  .error_field {
    color: #e25151;
    font-weight: 500;
    font-size: 12px;
    min-height: 15px;
  }
}

// .previewSuggestion-wrap {
//   background-color: rgba(67, 137, 250, 0.1);
//   border-radius: 24px;
//   padding: 1.5rem;
// }
