@import "../../../styles/base.scss";

// .bluebtn {
//   background: #1f75fe;
//   border-radius: 20px;
//   color: white;
//   padding: 4px 16px;
//   margin: 0;
//   margin-right: 8px;
//   cursor: pointer;
// }

// .whiteBtn {
//   background: white;
//   border-radius: 20px;
//   color: #1f75fe;
//   padding: 4px 16px;
//   margin: 0;
//   margin-right: 8px;
//   cursor: pointer;
//   border: 1px solid #1f75fe;
//   border-radius: 20px;
// }

// .ant-select {
//   border: none;
//   background: transparent;
//   .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//     border: none;
//     background: transparent;
//   }
// }

.companyDetailScreen {
  height: auto;
  width: 100%;
  overflow-x: hidden;
  .companymaincardsection {
    .companymaincard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #261c4b;
      background: white;
      border-radius: 10px;
      margin-bottom: 14px;
      padding: 12px;
      margin-top: 0px;
      .companymaincardInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #4389fa;
      }
    }
    .companycard {
      margin-top: 15px;
      .companycardwrap {
        .companyimagewrap {
          width: 30%;
          .companyLogo {
            margin-right: 20px;
            width: 90%;
          }
        }
        .companyDescriptionwrap {
          width: 70%;
          .cName {
            font-weight: 600;
            font-size: 17px;
          }
          .location {
            margin-bottom: 15px;
          }
          .cAttributes {
            width: 100%;
            .typeAndSize {
              display: flex;
              width: 100%;
              margin-bottom: 15px;
              .type,
              .size {
                width: 50%;
              }
            }
            .industryQuickLink {
              display: flex;
              width: 100%;
              .industry {
                width: 50%;
                padding-right: 5px;
              }
              .quickLinks {
                width: 50%;
              }
            }
          }
        }
      }
      .verticleDevider {
        margin: 0px 20px;
        height: 155px;
      }
      .cDescription {
        width: 70%;
        height: 155px;
        overflow: auto;
      }
    }
    .companySubCardsWrapper {
      width: 100%;
      .ant-tabs-content-holder {
        display: none;
      }
      .ant-divider-horizontal {
        display: none;
      }
      .ant-tabs {
        overflow: auto;
        width: 100%;
      }
      .ant-tabs-nav {
        margin: 0px;
        padding: 8px 16px 4px;
      }
      background: white;
      border-radius: 16px;
      .companySubCards {
        display: flex;
        align-items: center;
        // padding: 25px 25px 0px 25px;
        //border-bottom: 4px solid rgb(233, 241, 255);
        .bluebtn {
          background: white;
          border-radius: 0px;
          padding: 15px;
        }
      }
      .commonCardContainer {
        width: 100%;
        // margin-top: 20px;
        //padding: 25px;
        padding-top: 0px;
      }
      .ant-tabs-top > .ant-tabs-nav::before,
      .ant-tabs-bottom > .ant-tabs-nav::before,
      .ant-tabs-top > div > .ant-tabs-nav::before,
      .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .companyDetailScreen {
    .companymaincardsection {
      .companycard {
        .companycardwrap {
          width: 45%;
        }
        .verticleDevider {
          margin: 0px 15px;
        }
        .cDescription {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .companyDetailScreen {
    .companymaincardsection {
      padding: 15px;
      .companymaincard {
        .companymaincardInner {
          width: 100%;
          justify-content: flex-start;
          font-size: 16px;
        }
        .favClick {
          display: flex;
          position: fixed;
          bottom: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 15px;
          background: white;
          // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
        }
      }
      .companycard {
        flex-direction: column;
        align-items: baseline;
        .companycardwrap {
          width: 100%;
        }
        .verticleDevider {
          display: none;
        }
        .cDescription {
          width: 100%;
          border-top: 1px solid #999999;
          padding-top: 15px;
          margin-top: 15px;
        }
      }
      .companySubCardsWrapper {
        margin-bottom: 50px;
        .companySubCards {
          padding: 15px 15px 1px 15px;
          flex-wrap: wrap;
          .bluebtn {
            padding: 15px;
          }
        }
        .commonCardContainer {
          width: 100%;
          //padding: 15px;
          //padding-top: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 330px) {
  .companyDetailScreen {
    .companymaincardsection {
      .companycard {
        .companycardwrap {
          .companyDescriptionwrap {
            .cAttributes {
              width: 184%;
              margin-left: -43%;
            }
          }
        }
      }
    }
  }
}
