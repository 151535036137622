@mixin theflexstyle($direction, $justifyContent, $alignItems, $wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
}
.company__mainsection {
}

.card__conatiner,
.details_card__conatiner {
  background: #ffffff;
  box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 0 24px;
  margin-bottom: 16px;
  .card__header {
    @include theflexstyle(row, space-between, inherit, nowrap);
    border-bottom: 0.5px solid #e7e7e7;
    padding: 20px 0;
    .card_image__name {
      @include theflexstyle(row, space-between, center, nowrap);
      .companyLogo {
        cursor: pointer;
        margin-right: 20px;
        width: 15%;
        max-width: 60px;
        width: fit-content;
      }

      .card__name {
        .card_company__name {
          font-size: 18px;
          font-weight: 600;
        }
        .card_company__name,
        .card_company__companyraw {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .card__body {
    padding: 24px 0;
    display: flex;
    width: 100%;
    .card__body_right {
      width: 50%;
      .card__bodysection {
        @include theflexstyle(row, flex-start, center, nowrap);
        // gap: 20px;
        div {
          // gap: 20px;
          width: 100%;
          min-width: 0px;
          padding-bottom: 16px;
        }
        .card__bodysection_col {
          width: 50%;
          padding-right: 10px;
          padding-bottom: 10px;
          .card_body__heading {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #393939;
            margin: 0;
            width: 100%;
          }
          .card-body__subheading {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #606060;
            width: fit-content;
            max-width: 90%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
    .card_body_left {
      width: 50%;

      .card_body__heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #393939;
        margin: 0;
      }
      .card-body__subheading {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #606060;
        display: block;
        width: 100%;
        margin-bottom: 0;
        max-height: 200px;
        overflow: auto;
      }
    }
  }
}

.details_card__conatiner {
  .card__body {
    flex-direction: column;
    .card__body_right,
    .card_body_left {
      width: 100%;
    }
    .card__body_right {
      .card-body__subheading {
        width: fit-content;
        max-width: 90%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap !important; /* let the text wrap preserving spaces */
      }
    }
  }
}
